// Generated by Framer (e1877f1)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["pXMVxCyfM"];

const serializationHash = "framer-lgQ4c"

const variantClassNames = {pXMVxCyfM: "framer-v-13zqkgh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "pXMVxCyfM", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-13zqkgh", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"pXMVxCyfM"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--font-selector": "Q1VTVE9NO0FzcGVrdGEgNTAw", "--framer-font-family": "\"Aspekta 500\", \"Aspekta 500 Placeholder\", sans-serif", "--framer-font-size": "22px", "--framer-letter-spacing": "-0.7px", "--framer-line-height": "27px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-gdpscs, var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38)))"}}>Download all assets</motion.h1></React.Fragment>} className={"framer-36ggq1"} fonts={["CUSTOM;Aspekta 500"]} layoutDependency={layoutDependency} layoutId={"K73tQRaNP"} style={{"--extracted-gdpscs": "var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lgQ4c.framer-kz12f2, .framer-lgQ4c .framer-kz12f2 { display: block; }", ".framer-lgQ4c.framer-13zqkgh { height: 27px; overflow: hidden; position: relative; width: 190px; }", ".framer-lgQ4c .framer-36ggq1 { flex: none; height: auto; left: 50%; position: absolute; top: 48%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 190
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerD_CZimR4i: React.ComponentType<Props> = withCSS(Component, css, "framer-lgQ4c") as typeof Component;
export default FramerD_CZimR4i;

FramerD_CZimR4i.displayName = "Download all assets button";

FramerD_CZimR4i.defaultProps = {height: 27, width: 190};

addFonts(FramerD_CZimR4i, [{explicitInter: true, fonts: [{family: "Aspekta 500", source: "custom", url: "https://framerusercontent.com/assets/QCxAs6MMcxwaYpPK6ocd4TbXESU.woff2"}]}], {supportsExplicitInterCodegen: true})